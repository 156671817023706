import gql from "graphql-tag";

export const GET_CAMPAIGN_MENU = gql`
  query GetCampaignMenu($uri: String!) {
    campaignMenu: nodeByUri(uri: $uri) {
      ... on Campaign {
        id
        campaignMenu {
          menuItem {
            cssClasses
            label
            link
          }
        }
      }
    }
  }
`;
