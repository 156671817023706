<template>
  <div>
    <CampaignHeader :menu="campaignHeaderMenu" />

    <slot />

    <SiteFooter
      :collections="footerCollectionsMenu"
      :neighborhoods="footerNeighborhoodsMenu"
    />
  </div>
</template>

<script setup>
import { useQuery } from "@vue/apollo-composable";

const route = useRoute();
import { GET_CAMPAIGN_MENU } from "~/graphql/GetCampaignMenu";
const { result: campaignMenuResult, loading: campaignMenuLoading } = useQuery(
  GET_CAMPAIGN_MENU,
  {
    uri: route.path,
  }
);
const campaignMenu = computed(() => {
  return campaignMenuResult.value?.campaignMenu?.campaignMenu?.menuItem ?? [];
});
const campaignHeaderMenu = computed(() => {
  return campaignMenu.value.map((item, index) => {
    return {
      id: index + 1,
      label: item.label,
      uri: item.link,
      cssClasses: [item.cssClasses],
    };
  });
});

import { GET_MENUS } from "~/graphql/GetMenus";
const { result: menusResult, loading: menusLoading } = useQuery(GET_MENUS);

const footerCollectionsMenu = computed(() => {
  return menusResult.value?.footerCollectionsMenu?.nodes ?? [];
});

const footerNeighborhoodsMenu = computed(() => {
  return menusResult.value?.footerNeighborhoodsMenu?.nodes ?? [];
});
</script>
